import { Route, Routes, NavLink, useLocation } from 'react-router-dom';

import logo_meyoli from './media/logo-meyoli.png';
import social_facebook_green from './media/social-facebook-green.png';
import social_instagram_green from './media/social-instagram-green.png';
import social_linkedin_green from './media/social-linkedin-green.png';
import social_twitter_green from './media/social-twitter-green.png';
import ButtonLink from './components/ButtonLink';
import Menu from './components/Menu';
import { useEffect, useRef } from 'react';

import Home from './pages/Home';
import About from './pages/About';
import NotFound from './pages/NotFound';

function App() {

  // Create a ref for the target div
  const targetDivRef = useRef<HTMLInputElement>(null);
  const pathname = useLocation().pathname + Math.random().toString();
  
  useEffect(() => {
    // Check if the target div ref is valid before scrolling
    if (targetDivRef.current) {
      targetDivRef.current.scrollIntoView({
        behavior: 'smooth', // You can use "auto" instead for immediate scrolling
        block: 'start', // Change to "center" if you want the div at the center of the viewport
      });
    }
  }, [pathname]); // The empty dependency array ensures that this effect runs only once after component load

  return (

    <div className='flex border-box fullWidth padding-32 justify-center' ref={targetDivRef} id="targetDiv">
      <div className='fullWidth content-max-width'>

        <header id='small-menu' className='flex fullWidth space-between content-align-center margin-bottom-32'>
          <Menu />
          <NavLink to={"/"}><img src={logo_meyoli} className="main-logo" alt="logo" /></NavLink>
          <span><div style={{ width: 40, height: 1 }}></div></span>
          {/*<span><ButtonLink text='Login' /></span>*/}
        </header>

        <header id='large-menu' className='flex fullWidth space-between content-align-center margin-bottom-32'>
          <span className='flex'>
            <NavLink to={"/"}><img src={logo_meyoli} className="main-logo" alt="logo" /></NavLink>
            {/*<div style={{ width: 131, height: 1 }}></div>*/}
          </span>
          <span>
            <ButtonLink variant="transparent" text='Home' linkTo='/' />
            {/*<ButtonLink variant="transparent" text='Services' linkTo='/' />*/}
            <ButtonLink variant="transparent" text='About Us' linkTo='/about' />
            {/*<ButtonLink variant="transparent" text='Resources' linkTo='/' />*/}
            {/*<ButtonLink variant="transparent" text='Contact' linkTo='/' />*/}
          </span>
          <span>
            <div style={{ width: 131, height: 1 }}></div>
            {/*<span><ButtonLink text='Login' /></span>*/}
            {/*<span className='margin-left-16'><ButtonLink variant="highlight" text='Talk to Us' /></span>*/}
          </span>
        </header>


        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="*" element={<NotFound />} />
        </Routes>


        <footer className='flex justify-center standard-background-color footer-width position-absolute-left'>
          <div id='footer' className='flex space-between content-align-top fullWidth content-max-width padding-32'>
            <div id='footer-item'>
              <NavLink to={"/"}><img src={logo_meyoli} className="main-logo" alt="logo" /></NavLink>
              <p>Guiding success, igniting possibilities</p>
            </div>
            <div id='footer-item'>
              <div>
                {/*<p className='margin-0'>Services</p>*/}
                <NavLink to={"/about"}><a href="#"><p>About Us</p></a></NavLink>
                {/*<p>Resources</p>*/}
                {/*<p>Contact</p>*/}
              </div>
            </div>
            {/*<div id='footer-item'>
                <div>
                  <div className='margin-0'>
                    <h5 className='margin-0'>Email</h5>
                    <div>email@address.com</div>
                  </div>
                  <p>
                    <h5 className='margin-0'>Phone Number</h5>
                    <div>+123456789</div>
                  </p>
                </div>
              </div>*/}
            <div id='footer-item'>
              <div>
                <h3 className='margin-0 margin-bottom-16'>Our Socials</h3>
                <div>
                  {/*<span><img src={social_facebook_green} className="social-logo margin-right-16" alt="facebook" /></span>*/}
                  {/*<span><img src={social_twitter_green} className="social-logo margin-right-16" alt="twitter" /></span>*/}
                  <span><NavLink to={"https://www.linkedin.com/company/meyoli"}><img src={social_linkedin_green} className="social-logo margin-right-16" alt="linkedin" /></NavLink></span>
                  {/*<span><img src={social_instagram_green} className="social-logo margin-right-16" alt="instagram" /></span>*/}
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default App;