import React, { useState } from 'react';
import ButtonLink from './ButtonLink';

function Menu() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <React.Fragment>
            <div className={`menu-toggle ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
                <div className="menu-container">
                    <div className="hamburger-lines">
                        <span className="line line1"></span>
                        <span className="line line2"></span>
                        <span className="line line3"></span>
                    </div>
                </div>
                <div className='emptydiv'></div>
            </div>
            <div className={`menu ${isOpen ? 'open' : ''}`}>
                <ButtonLink variant="transparent" text='Home'      linkTo='/'      onClick={toggleMenu}/>
                {/*<ButtonLink variant="transparent" text='Services'  linkTo='/'      onClick={toggleMenu}/>*/}
                <ButtonLink variant="transparent" text='About Us'  linkTo='/about' onClick={toggleMenu}/>
                {/*<ButtonLink variant="transparent" text='Resources' linkTo='/'      onClick={toggleMenu}/>*/}
                {/*<ButtonLink variant="transparent" text='Contact'   linkTo='/'      onClick={toggleMenu}/>*/}
            </div>
        </React.Fragment>

    );
}

export default Menu;