import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function ButtonLink({ variant = 'standard', text, linkTo = '/', onClick }: { variant?: string, text: string, selected?: boolean, linkTo?: string, onClick?: any }) {

    const pathname = useLocation().pathname;

    if (pathname === linkTo) {
        variant = 'transparent selected';
    }

    return (
        <NavLink className={'button-style ' + variant} to={linkTo} onClick={onClick}>
            {text}
        </NavLink>
    );
}

export default ButtonLink;