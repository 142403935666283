import image_punch from '../media/image-punch.png';
import image_hand_shake from '../media/image-hand-shake.png';


function About() {
    return (
        <>
            <section className='flex fullWidth column-content content-align-center'>
                <h4 className='halfWidthMax text-aligment-center'>About Us</h4>
                <h2 className='halfWidthMax text-aligment-center'>
                    Welcome to a world of endless possibilities.
                    Welcome to <span className='text-highlight-color'>Meyoli.</span>
                </h2>
            </section>

            <section id='learn-more-seccion' className='about flex content-align-center space-between margin-top-64 margin-bottom-80'>
                <div className='flex justify-center percent-45-width-max'>
                    <div className='image-container width-fit-content'>
                        <img src={image_punch} className="image-size" alt="" />
                    </div>
                </div>
                <div className='halfWidthMax margin-0'>
                    <h5>Welcome to Meyoli! We are a small but passionate team dedicated to helping you turn
                        your dreams into reality. Our consultancy services and innovative software products
                        are designed to guide and empower you on your journey to success. We believe in building
                        genuine connections and providing unwavering support, making us more than just a service
                        provider. We measure our achievements by the positive impact we create in your life and
                        celebrate your victories as our own. Let us be your guiding light, igniting endless
                        possibilities and helping you transcend limitations. Trust Meyoli to be your partner
                        on this incredible adventure, and together, we will overcome obstacles and create a
                        future where success knows no bounds.
                    </h5>
                </div>
            </section>

            <section className='flex fullWidth column-content content-align-center'>
                <h1 className='halfWidthMax text-aligment-center'>Guiding Success Igniting <span className='text-highlight-color'>Possibilities</span></h1>
                <h5 className='halfWidthMax text-aligment-center'>
                    Is not just a slogan for us; it's the heartbeat of out company. We believe that success is not
                    a destination but a journey. We are here to be your guiding light, igniting the flame of
                    possibilities within you, and helping you transcend limitations.
                </h5>
            </section>

            <section id='learn-more-seccion' className='highligth-background-color padding-64 border-radius-16 margin-bottom-80'>
                <div className='percent-45-width-max'>
                    <h5 className='text-light-color'>
                        So, whether you're taking your first steps towards your dreams or looking to reach new heights,
                        trust Meyoli to be your partner on this incredible adventure. Together, we will overcome obstacles,
                        challenge the status quo, and create a future where success knows no bounds.
                    </h5>
                    <div className='margin-top-32'>
                        {/*<Button variant="light" text='Learn More About Us' />*/}
                    </div>
                </div>
                <div className='percent-45-width-max'>
                    <img src={image_hand_shake} className="green-image-size" alt="" />
                </div>
            </section>
        </>
    );
}

export default About;
