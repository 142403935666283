
import logo_cevt from '../media/logo-cevt.png';
import logo_liljewall from '../media/logo-liljewall.png';
import logo_vasttrafik from '../media/logo-vasttrafik.png';
import logo_zeekr from '../media/logo-zeekr.png';
import logo_afry from '../media/logo-afry.png';
import image_sphere_green from '../media/image-sphere-green.png';
import image_code from '../media/image-code.png';
import image_coffee from '../media/image-coffee.png';
import image_girl from '../media/image-girl.png';
import image_graph from '../media/image-graph.png';
import image_chart_circle from '../media/image-chart-circle.png';
import image_girl_chart_blue from '../media/image-girl-chart-blue.png';
import image_desktop from '../media/image-desktop.png';
import Card from '../components/Card';
import ButtonLink from '../components/ButtonLink';

function Home() {
    return (
        <>
            <section className='flex fullWidth column-content content-align-center standard-background-color border-radius-8'>
                <h1 className='halfWidthMax text-aligment-center'>Guiding Success Igniting <span className='text-highlight-color'>Possibilities</span></h1>
                <p className='halfWidthMax text-aligment-center'>Welcome to Meyoli, where we are dedicated to guiding your success and 
                igniting endless possibilities. With a passion for excellence and a commitment to innovation, we are your 
                trusted partner on the path to achieving your goals.
                </p>
                <div className='margin-top-16 margin-bottom-80'>
                    {/*<Button variant="highlight" text='Join Us Now' />*/}
                 <span className='margin-left-16'><ButtonLink variant="dark" text='Learn More' linkTo = '/about' /></span>
                </div>
                <img src={image_sphere_green} className="cropped-image" alt="" />
            </section>

            <section className='flex fullWidth column-content content-align-center'>
                <h2 className='text-aligment-center'>We're Proud of Companies that We Work With</h2>
                <p className='margin-0 text-aligment-center'>Celebrating strong and successful partnerships: Showcasing the companies we're proud to work with</p>
                <div className='flex flex-warp justify-center margin-top-64 margin-bottom-32'>
                    <span><img src={logo_zeekr} className="company-logo margin-bottom-32" alt="" /></span>
                    <span><img src={logo_cevt} className="company-logo margin-bottom-32" alt="" /></span>
                    <span><img src={logo_vasttrafik} className="company-logo margin-bottom-32" alt="" /></span>
                    <span><img src={logo_liljewall} className="company-logo margin-bottom-32" alt="" /></span>
                    <span><img src={logo_afry} className="company-logo margin-bottom-32 padding-8 padding-left-16" alt="" /></span>
                </div>
            </section>

            <section className='flex fullWidth column-content content-align-center'>
                <h2 className='halfWidthMax text-aligment-center'>What Can We Help You With</h2>
                <p className='halfWidthMax text-aligment-center'>Ignite success and embrace limitless possibilities with Meyoli. 
                Our expert consultancy and innovative product development empower you to achieve your goals and unlock your full potential.
                </p>
            </section>

            <section className='flex flex-warp justify-center'>
                <Card image={image_code} title="Unleashing Software's Potencial, Amplifying Your Success" text="Harness the boundless power of software to amplify your success, as we unlock its full potencial and drive your business to new heights." />
                <Card image={image_coffee} title="Elevate Your Potencial, Lead the Way." text="Discover the driving force behind your success with our exceptional services, positioning you as the true leader in your industry." />
                <Card image={image_girl} title="Ignite Your Potencial, Redifine Success" text="Discover unparalleled support and innovative solutions that ignite your potencial, propelling you to redefine success in a league of your own." />
                <Card image={image_graph} title="Ignite Your Dreams, Embrace Limitless Possibilities." text="Embrace a transformative journey where your dreams ignite, opening doors to endless possibilities and boundless success." />
                <Card image={image_chart_circle} title="Inspiring Dreams, Embracing Brilliance" text="Let our services ignite your passion, inspire your dreams, and embrace the brilliance within you, unlocking a world of infinite possibilities." />
            </section>

            <section id='learn-more-seccion' className='flex content-align-center space-between margin-top-64 margin-bottom-80'>
                <div className='percent-45-width-max margin-0'>
                    <h2 className='margin-0'>We are driven by a shared purpose: to ignite possibilities</h2>
                    <p className='margin-0'>At Meyoli, we believe that success is not a destination but a journey. 
                    Our experienced team of experts is here to provide you with the guidance and support you need 
                    to navigate the complex landscape of business and beyond. We undestand that each venture is unique, 
                    and we tailor our approach to meet your specific needs, ensuring a personalized and impactful experience.</p>
                    <div className='margin-top-32'>
                        <ButtonLink variant="highlight" text='Learn More About Us' linkTo = '/about'/>
                    </div>
                </div>
                <div className='flex justify-center percent-45-width-max'>
                    <div className='image-container width-fit-content'>
                        <img src={image_girl_chart_blue} className="image-size" alt="" />
                    </div>
                </div>
            </section>

            <section id='learn-more-seccion' className='highligth-background-color padding-64 border-radius-16 margin-bottom-80'>
                <div className='percent-45-width-max'>
                    <h2 className='margin-0 text-light-color'>Let Us Guide Your Success And Ignite A World Of Endless Possibilities</h2>
                    <p className='text-light-color'>Join us on the exhilarating journey of growth, as we partner with you 
                    to shape a future brimming with possibilities. Together, we will overcome obstacles, 
                    chart new territories, and make your vision a reality.</p>
                    <div className='margin-top-32'>
                        <ButtonLink variant="light" text='Learn More About Us' linkTo = '/about'/>
                    </div>
                </div>
                <div className='percent-45-width-max'>
                    <img src={image_desktop} className="green-image-size" alt="" />
                </div>
            </section>
        </>
    );
}

export default Home;
