function Card({image, title, text}: {image: any, title: String, text: String}) {
    return(
        <div className='flex card-max-width column-content content-align-center margin-top-16'>
            <div className='image-container'>
                <img src={image} className="image-size" alt="logo" />
            </div>
            <h3 className='text-aligment-center text-width'>{title}</h3>
            <p className='text-aligment-center text-width margin-0 margin-bottom-16'>{text}</p>
        </div>
    );
}

export default Card;